/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Moment from 'moment';
import "custom-event-polyfill";
import 'rs-ui/rs-ui.css';
import 'ie11-scroll-into-view';

// IE11 - Event constructor polyfill
(function () {
  if (typeof Event !== 'function') {
    window.Event = CustomEvent;
  }
})();

document.addEventListener('DOMContentLoaded', () => {
  if (typeof (moment) === "undefined") { window.moment = Moment; };
})
